import jquery from 'jquery';
window.$ = window.jQuery = jquery;

$( document ).on('turbolinks:load', function() {
  $('.alert').fadeOut(5000);
  $('.merchants').repeater();
  $('.prices').repeater();
  $('#survey_id').on('change', function() {
    $.ajax({
      url: '/participants/new',
      type: "GET",
      data: {
        survey_id: $(this).val()
      }
    });
  })

  $('.filter-select').on('change', function() {
    var url = window.location.pathname
    if ($(this).val() != '') {
      window.location.href = url + '?filter=' +  $(this).val()
      // $.ajax({
      //   dataType: 'JSON',
      //   url:  url + '?filter=' +  $(this).val(),
      //   type: "GET",
      //   success: function(response) {
      //     $('.participants tbody').html(response.template)
      //   }
      // });
    }
  })
  flatpickr(".date-input", {
    enableTime: true,
    dateFormat: "Y-m-d H:i",
    locale: 'Singapore'
  })
  // $('.pagination a').on('click', function() {
  //   var url = $(this).attr('href')
  //   var filter = $('.filter-select').val()
  //   $.ajax({
  //     dataType: 'JSON',
  //     url:  url + '&filter=' +  filter,
  //     type: "GET",
  //     success: function(response) {
  //       $('.participants tbody').html(response.template)
  //     }
  //   });
  // })
})
